import React from "react";
import Layout from "../components/layout";

export default function Contact() {
    return (
        <Layout>
            <div className="container">
                <h1 style={{ paddingTop: `2rem`}}>I'd love to talk! Email me at <a href="mailto:thewebpleb@gmail.com">thewebpleb@gmail.com</a></h1>
            </div>  
        </Layout>
    );
}